html {
    padding: 0;
    margin: 0;
  }
  
  body {
    display: block;
    height: 100%;
    padding: 0;
    overflow: hidden;
  }
  
  #root {
    height: 100%;
  }