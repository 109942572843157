$open-sans-condensed-path:'../fonts/OpenSansCondensed-Bold.ttf';

@font-face {
    font-family: 'OpenSansCondensed';
    src: url($open-sans-condensed-path) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @mixin ui-font {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;
    color: $default-text;
  }
  
  body {
    font-family: 'OpenSansCondensed';
    margin: 0;
  }
  
  h1, h2, h3, h4, h5 {
    font-family: 'OpenSansCondensed';
    margin: 0 0 1.25rem;
    line-height: 1.25;
    color: $default-text;
  }
  h1 {
    font-size: 2.281rem;
  }
  h2 {
    font-size: 2.027rem;
  }
  h3 {
    font-size: 1.802rem;
  }
  h4 {
    font-size: 1.424rem;
  }
  h5 {
    font-size: 1.266rem;
  }
  h6 {
    font-size: 1.125rem;
    color: $default-text;
  }
  p {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 1rem;
    line-height: 1.25;
  }

  a, a:visited {
    color: $dark-teal;
  }
  
  .small {
    font-size: .9rem;
  }

  .emphasis {
    font-weight: bold;
    color: $green;
  }