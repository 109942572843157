@import "reset";
@import "media";
@import "colors";
@import "blocks";
@import "typography";

// Password Modal CSS

.app-container {
  background-image: url("../images/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center bottom;
  display: block;
  height: 100%;
  padding: 0;
  overflow: hidden;
  transition: background-color 0.3s ease;
}

.password-correct {
  background-image: none;
  background-color: $whiteish;
}

.password-popup-container {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  padding: 20px;

  .password-popup-modal {
    border-radius: 8px;
    border: 1px solid $lightest-gray;
    border-right: 1px solid $lighter-gray;
    border-bottom: 1px solid $lighter-gray;
    background: $off-white;
    padding: 2rem;
    box-shadow: 0 0 3px 3px transparentize($dark-gray, 0.9);
    transition: all 0.2s ease-in-out;
    text-align: center;

    h2 {
      color: $green;
      font-variant: small-caps;
      font-weight: 500;
      font-size: 2.4em;
      margin-bottom: 0;
      padding-bottom: 20px;

      @media (max-width: 600px) {
        font-size: 2em;
      }
    }

    input {
      margin-left: 0.25rem;
    }

    button {
      margin: 0.75rem 0;
      margin-left: 1rem;
      padding: 0.5rem;
      border-radius: 10px;
      border: 0;
      background-color: $green;
      color: white;
      cursor: pointer;

      @media (max-width: 600px) {
        margin-left: 0;
      }
    }

    .incorrect-password-message {
      color: red;
      font-size: 0.85rem;
      font-style: italic;
    }
  }

  .logo-container {
    display: block;
    text-align: center;
    margin-top: 1.5rem;

    img {
      width: 80%;
      margin: 10px 0;
    }
  }
}

// Initializing CSS

.initializingContainer {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.4);

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -50px 0 0 -50px;
    width: 100px;
    height: 100px;
    text-align: center;
    font-size: 50px;
    z-index: 2;
  }
}

// Map CSS

.mapContainer {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.mapboxgl-ctrl-bottom-right {
  display: none;
}

.mapboxgl-ctrl-bottom-left {
  right: 10px !important;
  left: auto !important;
}

// Sidebar CSS

.sidebarContainer {
  position: absolute;
  width: 45%;
  max-width: 25rem;
  height: 100%;
  right: 0;

  .tab {
    cursor: pointer;
    color: transparentize(white, 0.6);
    position: absolute;
    top: 0.5rem;
    left: -2rem; // same as width of tab
    width: 2rem;
    height: 4rem;
    line-height: 3.9rem;
    text-align: center;
    border-radius: 6px 0 0 6px;
    background: $dark-blue;
    box-shadow: 3px 2px 3px transparentize($dark-gray, 0.9);
    transition: all 0.2s ease-in-out;
  }

  .closeMe {
    display: none;
  }

  .sidebar {
    position: absolute;
    height: 100%;
    width: 100%;
    background: white;
    border: 1px solid $lightest-gray;
    border-right: 1px solid $lighter-gray;
    border-bottom: 1px solid $lighter-gray;
    background: $off-white;
    padding: 0.5rem;
    box-shadow: 0 0 3px 3px transparentize($dark-gray, 0.9);
    transition: all 0.2s ease-in-out;
    word-wrap: break-word;
  }

  &.onCanvas {
    .sidebar {
      display: block;
      top: 0;
      right: 0;
    }

    .tab {
      &:after {
        content: "▶";
      }
    }
  }

  &.offCanvas {
    @media screen and (min-width: 600px) {
      pointer-events: none;
    }

    .sidebar {
      right: -100%;
    }

    .tab {
      pointer-events: all;

      &:after {
        content: "◀";
      }

      left: 92%;
    }
  }

  .bar {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  button {
    margin: 0.75rem 0;
    padding: 0.5rem;
    border-radius: 10px;
    border: 0;
    background-color: $green;
    color: white;
    cursor: pointer;
  }

  .sidebar--logo {
    margin: 0;
    padding: 1rem 0;

    h2 {
      color: $green;
      font-variant: small-caps;
      font-weight: 500;
      font-size: 2.4em;
      margin-bottom: 0;
      text-align: center;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .sidebar--intro {
    display: none;
    background-color: $dark-blue;
    padding: 1rem;

    * {
      color: white;
    }
  }

  .sidebar--nav {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-family: sans-serif;
    margin-bottom: 0.75rem;

    &-filter,
    &-intro,
    &-credits {
      font-family: "OpenSansCondensed";
      color: $default-text;
    }

    &-credits p {
      font-size: 0.85em;
    }

    .categoryFilter .storiesSort {
      margin: 0.5rem 0;
    }
  }

  .intro-panel {
    height: 100%;
    margin: 0 0.5rem;
    display: block;
    overflow: auto;
    overflow-x: hidden;

    h3 {
      color: $dark-teal;
    }

    p {
      margin-bottom: 1rem;
      font-size: 0.9rem;
    }

    img {
      width: 100%;
      margin-bottom: 10px;
    }

    .icon {
      float: left;
      width: 30px;
      height: 30px;
      margin: 5px 10px 10px 0;
    }

    .logos {
      max-width: 90%;
    }
  }

  .stories {
    overflow-x: hidden;
    flex: 5 0 0;

    .container {
      padding-bottom: 1rem;
    }

    li {
      &.isActive {
        border: 3px solid $dark-blue;
      }

      list-style: none;
      padding: 0.75rem;
      background: white;
      margin-bottom: 0.5rem;
      box-shadow: $el-shadow;

      #locateOnMap {
        float: right;
      }

      .thumbnail {
        width: 50px;
        height: 50px;
        border-radius: 3px;
        float: right;
        margin: 0 0 1rem 1rem;

        &.placeholder {
          background-color: $light-gray;
        }
      }

      .title {
        overflow-wrap: break-word;
      }

      p {
        font-size: 0.85rem;
        overflow-wrap: break-word;
        line-height: 1.25;
        margin-top: 1rem;
        @include ui-font;
        padding-bottom: 0.5rem;

        b {
          font-weight: bold;
        }
      }

      #extraContent {
        border: 1px dotted $dark-gray;
        background-color: $off-white;
        padding: 4px;
      }

      pre {
        overflow-x: auto;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
        font-size: 0.75rem;
      }

      #csv,
      #locateOnMap {
        cursor: pointer;
        width: 25px;
      }
    }
  }

  #noStories {
    p {
      padding: 5px;
    }
  }
}

// Additional filters CSS

.additionalFiltersWrapper {
  &.show {
    display: block;
  }

  &.hide {
    display: none;
  }

  .additionalFiltersContainer {
    position: absolute;
    width: 26rem;
    max-height: 65%;
    height: auto;
    left: 10px;
    bottom: 0;

    .tab {
      cursor: pointer;
      color: transparentize(white, 0.6);
      position: absolute;
      top: -2rem;
      left: 0.5rem;
      width: 10rem;
      height: 2rem;
      line-height: 2rem;
      text-align: center;
      border-radius: 6px 6px 0 0;
      background: $dark-blue;
      box-shadow: 3px 2px 3px transparentize($dark-gray, 0.9);
      transition: all 0.2s ease-in-out;
    }

    .tabOffCanvas {
      cursor: pointer;
      color: transparentize(white, 0.6);
      position: absolute;
      bottom: 0rem;
      left: 0.5rem;
      width: 10rem;
      height: 2rem;
      line-height: 2rem;
      text-align: center;
      border-radius: 6px 6px 0 0;
      background: $dark-blue;
      box-shadow: 3px 2px 3px transparentize($dark-gray, 0.9);
      transition: all 0.2s ease-in-out;
    }

    .closeMe {
      display: none;
    }

    .additionalFilters {
      position: absolute;
      background: white;
      height: auto;
      border: 1px solid $lightest-gray;
      border-right: 1px solid $lighter-gray;
      border-bottom: 1px solid $lighter-gray;
      background: $off-white;
      padding: 0.5rem;
      box-shadow: 0 0 3px 3px transparentize($dark-gray, 0.9);
      transition: all 0.2s ease-in-out;
      word-wrap: break-word;

      .additionalFiltersBody {
        overflow-y: auto;
        height: 100%;

        .additionalFiltersIntro {
          padding: 5px 0;
        }

        h6 {
          margin-bottom: 15px;
          color: $dark-teal;
        }

        p {
          font-size: 0.85rem;
          overflow-wrap: break-word;

          b {
            font-weight: bold;
          }
        }

        fieldset {
          margin-bottom: 15px;

          label {
            font-size: 0.85rem;
            overflow-wrap: break-word;
            @include ui-font;
            padding-right: 5px;
          }

          div {
            display: inline-flex;
            flex-wrap: wrap;
          }

          p {
            font-size: 0.85rem;
            @include ui-font;
          }
        }
      }
    }

    &.onCanvas {
      .additionalFilters {
        display: block;
        bottom: 0;
      }

      .tab {
        &:after {
          content: "Filter municipalities ▼";
        }
      }

      .tabOffCanvas {
        display: none;
      }
    }

    &.offCanvas {
      @media screen and (min-width: 600px) {
        pointer-events: none;
      }

      .additionalFilters {
        bottom: -50rem;
      }

      .tabOffCanvas {
        pointer-events: all;
        display: block;

        &:after {
          content: "Filter municipalities ▲";
        }
      }

      .tab {
        pointer-events: all;

        &:after {
          content: "Filter municipalities ▲";
        }
      }
    }
  }
}

// Parcels

.showParcelsContainer {
  position: absolute;
  max-height: 65%;
  height: auto;
  left: 50px;
  top: 28px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  border-radius: 4px;
}

// Responsive patches

@include for-phone-only {
  .initializingContainer {
    height: 50%;
    width: 50%;
  }

  .showParcelsContainer {
    left: 50px;
    top: 28px;
  }

  .additionalFiltersContainer {
    display: none !important;
  }

  .sidebarContainer {
    &.onCanvas {
      width: 0;

      .closeMe {
        display: none;
      }

      .sidebar {
        top: 0;
        right: -100vw;
      }

      .tab {
        &:after {
          content: "";
        }
      }
    }

    &.offCanvas {
      width: 100%;
      left: 0;

      .sidebar {
        top: 0;
        left: 0;
      }
    }

    .closeMe {
      display: block;
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      color: $black;
      font-family: sans-serif;
      font-size: 1.25rem;
      cursor: pointer;
      z-index: 2;

      &:after {
        content: "✕";
      }
    }

    .sidebar {
      width: 100vw;
    }

    .tab {
      &:focus {
        outline: none;
      }

      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-tap-highlight-color: transparent;

      &:after {
        content: "";
      }

      .arrow {
        width: 0;
        height: 0;
        border-top: 12px solid transparent;
        border-left: 15px solid rgba(255, 255, 255, 0.9);
        border-bottom: 12px solid transparent;
        margin-top: 1.1rem;
        margin-left: 0.5rem;
      }

      background: orange;
      right: 0;
      top: 0.5rem;
      border-radius: 0;
    }
  }
}
