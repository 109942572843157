$teal: #5aa68d;

$dark-teal: #09697e;

// Official Colors from Designer
$red: #ef502e;
$orange: #DD5F0B;
$blue: #09697e;
$green: #33aa8b;
$yellow: #dea826;

// Grays
$card-background: rgba(255,255,255,0.70);

$white: #ffffff;
$whiteish: #f6f6f6;
$off-white: #f8f8f8;
$lightest-gray: #d7d7d7;
$lighter-gray: #bbbbbb;
$light-gray: #a6a6a6;
$mid-gray: #7A7A7A;
$medium-gray: #54595F;
$dark-gray: #666666;
$darker-gray: #3a3a3a;
$black: #000000;

// blues
$light-blue: #179caa;
$medium-blue: $blue;
$dark-blue: #0f566b; // does not match palatte since dark blue is text color
$deep-blue: #05323a;

// greens
$light-green: #4dd5ae;
$medium-green: $green;
$dark-green: #227a61;

// oranges
$light-orange: #ff9c43;
$medium-orange: $orange;
$dark-orange: #b55915;

// reds
$light-red: #ff6850;
$medium-red: $red;
$dark-red: #c93017;

// yellows
$light-yellow: #c1880a;
$medium-yellow: $yellow;
$dark-yellow: #ffc33e;

// alpha hexs
$transparent-white: #efefef80;
$transparent-white-hover: #efefefb0;

// App Helpers
$text-color: $deep-blue;

$background: $white;
$secondary-background: $whiteish;

$default-text: $text-color;
$secondary-text: $medium-gray;

$el-shadow: 0 1px 4px $lighter-gray;